import type { ISharedKitButtonColorPreset } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';

type TColorPresetsKeys = 'TAB' | 'DAILY_CASES';

export const MainPageButtonPreset: Record<TColorPresetsKeys, ISharedKitButtonColorPreset> = {
  DAILY_CASES: {
    defaultBackground: {
      colorStops: ['#FF76FA 0%', '#FFCA43 100%'],
      toDirection: '90deg',
    },
    hoverBackground: {
      colorStops: ['#FFCA43 0%', '#FF76FA 100%'],
      toDirection: '90deg',
    },
    loadingColor: Colors.NEUTRAL.BLACK_30,
    textColor: Colors.NEUTRAL.BLACK,
  },
  TAB: {
    defaultBackground: Colors.NEUTRAL.WHITE_15,
    // Цвет ховера должен быть 0.3 прозрачности, снизу 0.15 из-за наложения
    hoverBackground: Colors.NEUTRAL.WHITE_15,
    loadingColor: Colors.NEUTRAL.BLACK_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
};

export const EventButtonPreset = (background: string[], hover: string[]): ISharedKitButtonColorPreset => {
  return {
    defaultBackground: {
      colorStops: background,
      toDirection: '98deg',
    },
    hoverBackground: {
      colorStops: hover,
      toDirection: '87deg',
    },
    hoverTextColor: Colors.BUTTON_PRESET.PRIMARY.HOVER,
    loadingColor: Colors.BUTTON_PRESET.PRIMARY.LOADING,
    textColor: Colors.BUTTON_PRESET.PRIMARY.TEXT,
  };
};
